import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Button, Modal, Row, Col } from 'react-bootstrap';
import { toast } from "react-toastify";
import axios from 'axios';

const CriminalCheckModal = ({ show, onHide, fetchCustomerData }) => {
    const [required, setRequired] = useState(false);
    const { user } = useSelector(state => state.userAuth);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        middle: '',
        dob: '',
        ssn: '',
        houseNumber: '',
        streetName: '',
        city: '',
        state: '',
        zipcode: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        const id = user.id;
        e.preventDefault();
        setRequired(false);

        if (!formData.firstName || !formData.lastName || !formData.state || !formData.zipcode) {
            setRequired(true);
            return;
        }

        const ssnDigitsOnly = formData.ssn.replace(/\D/g, "");
        if (ssnDigitsOnly.length < 9) {
            alert("SSN must be at least 9 digits.");
            return;
        }

        const dob = new Date(formData.dob);
        const formattedDob = `${String(dob.getMonth() + 1).padStart(2, "0")}-${String(
            dob.getDate()
        ).padStart(2, "0")}-${dob.getFullYear()}`;

        const payload = {
            reference: "myRef123",
            subjectInfo: {
                first: formData.firstName,
                last: formData.lastName,
                middle: formData.middle,
                dob: formattedDob,
                ssn: formData.ssn,
                houseNumber: formData.houseNumber,
                streetName: formData.streetName,
                city: formData.city,
                state: formData.state,
                zip: formData.zipcode,
            },
            userId: id,
        };

        try {
            const response = await axios.post(
                process.env.REACT_APP_API_CRMINAL_CHECK,
                payload
            );
            if (response.status === 200 || response.status === 201) {
                console.log("API Response:", response.data);
                toast.success(response.data.message || "Criminal check request submitted successfully!");
                fetchCustomerData();
                onHide();
            } else {
                console.error("Unexpected Response Status:", response.status);
                toast.error(response.data.message || "Unexpected response from the server. Please try again.");
            }
        } catch (error) {
            console.error("API Error:", error.response ? error.response.data : error.message);
            toast.error(error.response ? error.response.data : error.message || "Failed to submit criminal check request. Please try again.");
        }
    };


    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='model-custom-dsg'>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Criminal Check
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <div className='pop-up-step-one'>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="firstName">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Input First Name"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                               
                                <Form.Group className="mb-3" controlId="dob">
                                    <Form.Label>Date of Birth</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="dob"
                                        value={formData.dob}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="houseNumber">
                                    <Form.Label>House Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Input House Number"
                                        name="houseNumber"
                                        value={formData.houseNumber}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="city">
                                    <Form.Label>City</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Input City"
                                        name="city"
                                        value={formData.city}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="lastName">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Input Last Name"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="ssn">
                                    <Form.Label>SSN</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Input SSN"
                                        name="ssn"
                                        value={formData.ssn}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="streetName">
                                    <Form.Label>Street Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Input Street Name"
                                        name="streetName"
                                        value={formData.streetName}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="state">
                                    <Form.Label>State</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Input State"
                                        name="state"
                                        value={formData.state}
                                        onChange={handleInputChange}
                                    />
                                    <Form.Text className="text-muted" style={{ fontSize: "0.8rem" }}>
                                        Please use two-letter state abbreviations (e.g., CA, TX, NY).
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="zipcode">
                                    <Form.Label>Zipcode</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Input Zipcode"
                                        name="zipcode"
                                        value={formData.zipcode}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        {required && (
                            <Form.Group className="mb-3" controlId="formGridRequired">
                                <small className="text-danger from-input-description">All Fields are Required!</small>
                            </Form.Group>
                        )}
                        <Button variant="primary" type="submit">Submit</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default CriminalCheckModal;
